import axios from "axios"

export const http = axios.create({
    baseURL: "https://api.piracouro.com.br/",
    headers: 
    {
        "Access-Control-Allow-Origin": "*",
        
    }
})

