import {http} from "./http"

export default {
    getByCode: (code)=>{
        return http.get("workorder/getbycode",{ 
            params: {
                code: code
            }
        })    
    }
}