<template>
  <el-row >
    <el-col :sm="24" :md="10" class="informacoes">
      <div class="phone">        
          <a href="tel:1934336774"> 
            <el-tooltip class="item phone-orange " effect="light"  placement="bottom-start" >
              <template #content>
                <a href="tel:1934336774"> Ligar para (19) 3433-6774</a>
              </template>
              <div>
              <i class="el-icon-phone-outline"></i>
              <span>(19) 3433-6774</span>
              </div>
            </el-tooltip>
          </a>              
      
          <a target="_blank" href="https://wa.me/+551934336774">
            <el-tooltip class="item" effect="light"  placement="bottom-start" >    
            <template #content>
              <a target="_blank" href="https://wa.me/+551934336774">Enviar WhatsApp para (19) 3433-6774</a>
            </template>          
              <div>
                <img src="../assets/whatsapp.png" /> 
                (19) 3433-6774
              </div>
            </el-tooltip>
          </a>
        
      </div>      
      <div class="info-horario">  
        <p><b>Nosso horário de Atendimento</b>:</p>
        <p>Segunda a Sexta: 08:00 as 18:00</p>
        <p>Sabádo: 08:00 as 14:00</p>
        <br/>
        <p><b>Endereço:</b></p> <p>R.Benjamin Constant,1826</p><p> Piracicaba/SP</p>
      </div>
    </el-col> 
    <el-col :sm="24" :md="14" >      
      <div >
        <iframe class="mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14719.460378803053!2d-47.6506357!3d-22.7332551!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5dcee73f16dfc410!2sPiracouro!5e0!3m2!1spt-BR!2sbr!4v1613771209614!5m2!1spt-BR!2sbr"  style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </el-col> 
  </el-row>
</template>


<style scoped >

.info-horario{
  font-size: 15px;
  font-family: 'JosefinSans-Regular','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-top: 10px;
  line-height: 5px;
}
  .el-col{
    border: 0px solid green

  }
  .informacoes img{
    width: 25px;
    margin-right: 10px;
  }

  .informacoes i{
    width: 25px;
    margin-right: 10px;
  }

  .mapa{
    width: 100%;
    height: 400px;
  }
  .informacoes {    
    font-size: 25px;
    border-right: 0px solid green

  }
  .informacoes div{
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .informacoes{
    display: flex;
    flex-direction: column;
    
    font-family: 'JosefinSans-Regular','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    align-items: center;    
  }

  a:visited{
    color: black;
    text-decoration: none;
  }

  a:link {
    color: black;
    text-decoration: none;
  }

  .phone div:hover {
    box-shadow: 2px 2px;
  }


  .phone div{
    border-radius: 30px;
    border: 1px solid #80d274;    
    color: #80d274;    
    display: flex;    
    align-items: center;
    justify-items: center;
    padding: 15px;
  }

  .phone-orange{
    border-color: green !important;
    color: green!important;    
  }
</style>