<template>

<el-row type="flex" justify="center" style="margin-top:15px">
  <el-col :xs="24" :sm="15">
    Consulte o status do seu serviço
  </el-col>
</el-row>

<el-row type="flex" justify="center">
  <el-col :xs="24" :sm="15" :md="8">
    <el-input placeholder="Informe o Código da Comanda. Ex.: T0001" v-on:keyup.enter="search()"  v-model="searchCode" class="input-with-select">
      <template #append>
        <el-button icon="el-icon-search" @click="search(searchCode)" ></el-button>
      </template>
    </el-input>
  </el-col>
</el-row>

<div style="margin-top:50px" v-if="!showInfo" v-loading="loading">    
</div>

<transition   name="el-fade-in" :duration="500">
  <div v-show="showInfo">
    <div class="info-container"  >
      <div class="info">
        <p><label> Comanda:</label>  {{workOrder.code}} </p>
        <p><label> Data Cadastro:</label>  {{new Date(workOrder.createdDate).toLocaleDateString()}} </p>          
      </div>
      <div class="status-icon">
        <div v-if="isReady" class="info-status" style="color:green"><i class="el-icon-check"></i>Pronto  </div>
        <div v-if="isDelivered"  class="info-status" style="color:green" ><i class="el-icon-success" ></i>Concluído </div>
        <div v-if="isCanceled" class="info-status" style="color:red"><i class="el-icon-error"></i>Cancelado    </div>
        <div v-if="isTodo" class="info-status" style="color:blue"><i class="el-icon-time"></i>Em andamento    </div>
      </div>  
    </div>
    <span  v-if="isReady">Seu serviço está pronto e pode ser retirado em nossa loja. </span>
    <span  v-if="isTodo">Seu serviço está na fila para ser executado. Data prevista é <b>{{new Date(workOrder.expectedDate).toLocaleDateString()}}</b> </span>
    <span  v-if="isDelivered"> Seu serviço já foi concluído e retirado em nossa loja. <p>Agradecemos a preferência</p>  </span>
    <span  v-if="isCanceled"> Infelizmente seu serviço consta como cancelado em nosso sistema. </span>

    <div class="info-horario">  
      <p><b>Nosso horário de Atendimento</b>:</p>
      <p>Segunda a Sexta: 08:00 as 18:00</p>
      <p>Sabádo: 08:00 as 14:00</p>
    </div>
  </div>  
</transition>
<div v-show="message">
  {{message}}
</div>
</template>

<script>
import WorkOrder from "../services/workorder"
export default {
  name: 'StatusWO',  
  props: {id: String},
  data(){
    return{
      workOrder: {},
      searchCode: '',
      message:"" ,
      loading: false     
    }
  },
  methods:{
    
    search:function() {
      var code = this.searchCode;   
      this.workOrder = {}; 
      this.loading = true;  
      WorkOrder.getByCode(code).then(resp=> {
        this.loading = false;
        if(resp.data){
          this.workOrder = resp.data;
          this.message = ""

        }else{
          this.workOrder = {};
          this.message = "Nenhuma comanda encontrada"
        }

      })
      .catch(error => { 
        this.loading = false;
        this.workOrder = {};
        this.message = "Nenhuma comanda encontrada"
        if (error.response) {    
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {        
          console.log(error.request);
        } else {        
          console.log('Error', error.message);
        }      
      })
    }
  },
  mounted(){        
    this.searchCode = this.id;
    if(this.searchCode){
      this.search()
    }
  },
  computed:{
    showInfo: function(){
      return this.workOrder.code && this.workOrder.code.toLocaleLowerCase() == this.searchCode.toLocaleLowerCase();
    },
    showError: function(){
      return this.message;
    },
    isReady: function(){
      return this.workOrder.status && this.workOrder.status == 2;
    },
    isDelivered: function(){
      return this.workOrder.status && this.workOrder.status == 3;
    },
    isTodo: function(){
      return this.workOrder.status && this.workOrder.status < 2;
    },
    isCanceled: function(){
      return this.workOrder.status && this.workOrder.status == 4;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.info{
  text-align: left;
  padding: 15px;
}

.info label{
  font-weight: bold;
}

.status-icon {
  display: flex;
  align-items: center;
  padding: 40px;
  font-size: 15px;
}
.status-icon i{
  font-size: 25px;
}
.status-icon span::before{
  content: '\A';
}

.info-container{
  display: flex;
  justify-content: center;
}
.info-status{
  display: flex;
  flex-direction: column;
}

.info-horario{
  font-size: 15px;
  font-family: 'JosefinSans-Regular','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-top: 10px;
  line-height: 5px;
}

</style>
